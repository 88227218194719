import React from "react"
import styled from "@emotion/styled"
import { createSite as text } from "@modules/locales/default.json"
import { interpolateMessage } from "@modules/locales"
import { sourceControlProviderLabels } from "@modules/sourceControlProvider"
import {
  Button,
  SelectFieldBlock,
  InputFieldBlock,
  FormFieldBlock,
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  Text,
} from "gatsby-interface"
import Loading from "@modules/ui/components/Loading"
import { MdRefresh } from "react-icons/md"
import { useTracker } from "@modules/analytics"
import { SourceControlProvider } from "@modules/graphql/types"
import { useBranchesForSourceRepositoryQuery } from "@modules/site/create/Import/queries.generated"
import { BranchesCombobox } from "@modules/ui/components/BranchesCombobox"
import { useRepositorySearch } from "@modules/site/create/Import/hooks/useRepositorySearch"

const emphasizedCardCss = theme => ({
  position: `relative`,
  padding: theme.space[7],
  marginTop: `-${theme.space[7]}`,
  "&:before, &:after": {
    content: '" "',
    position: `absolute`,
    borderRadius: theme.radii[3],
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: -1,
  },
  "&:before": {
    backgroundImage: `linear-gradient(110deg, #8954a8 0, #663399 25%, #bc027f 50%, #ffdf37 75%, #05f7f4 100%)`,
    borderRadius: theme.radii[3],
    opacity: 0,
    transition: `opacity ${theme.transitions.default}`,
  },
  "&:after": {
    backgroundColor: theme.colors.white,
    margin: `2px`,
    borderRadius: `6px`,
  },
})

const emphasizedCardActiveCss = _theme => ({
  "&:before": {
    opacity: 1,
  },
})

export function RepoFieldsCard({ hasSelectedRepo = false, ...rest }) {
  return (
    <div
      css={theme => [
        emphasizedCardCss(theme),
        hasSelectedRepo && emphasizedCardActiveCss(theme),
      ]}
      {...rest}
    />
  )
}

const dummyInstallationOption = {
  value: "__none__",
  label: "Add new Organization",
}

export function InstallationField({
  label,
  sourceOrganizations,
  selectedInstallation,
  onSelect,
  popInstallationWindow,
  provider,
}) {
  return (
    <SelectFieldBlock
      id="installationField"
      name="installationField"
      label={label}
      options={sourceOrganizations
        .map(org => ({
          value: org.id,
          label: org.name,
        }))
        .concat(
          provider === SourceControlProvider.Github
            ? [dummyInstallationOption]
            : []
        )}
      onChange={e => {
        if (e.target.value === dummyInstallationOption.value) {
          popInstallationWindow(`Add New Organization`, {
            uiSource: `Repository Selection - Menu`,
          })
        } else {
          onSelect(e.target.value)
        }
      }}
      value={selectedInstallation}
      required
    />
  )
}

export function BranchField({
  nameWithOwner,
  selectedBranch,
  onSelect,
  title,
  provider = SourceControlProvider.Github,
}) {
  const { trackButtonClicked } = useTracker()
  const [searchCriteria, setSearchCriteria] = React.useState("")
  const { data } = useBranchesForSourceRepositoryQuery({
    variables: {
      searchText: searchCriteria,
      nameWithOwner,
      provider,
    },
  })

  return (
    <FormFieldBlock
      id="selectBranch"
      label={title}
      error={!selectedBranch ? `A branch must be selected` : null}
      required
    >
      {controlProps => (
        <BranchesCombobox
          branches={data?.branchesForSourceRepository?.branches || []}
          aria-label={title}
          inputId={controlProps.id}
          aria-describedby={controlProps["aria-describedby"]}
          selectedBranch={selectedBranch}
          onSearchCriteriaChange={setSearchCriteria}
          onBranchChange={branchName => {
            trackButtonClicked(`Branch list`, {
              siteId: undefined,
              uiSource: `Create and setup site`,
            })

            onSelect(branchName)
          }}
        />
      )}
    </FormFieldBlock>
  )
}

const refetchButtonCss = theme => ({
  paddingTop: theme.space[3],
  paddingBottom: theme.space[3],
  borderTop: `1px solid ${theme.colors.grey[20]}`,
  borderRadius: 0,
  width: `100%`,
  position: `absolute`,
  bottom: 0,
  left: 0,
  right: 0,
})

const ReposZeroState = styled(Text)(({ theme }) => ({
  margin: `${theme.space[3]} ${theme.space[5]}`,
  fontSize: `inherit`,
}))

export function RepoField({
  label,
  selectedInstallationId,
  selectedRepo,
  resetInterval,
  provider,
  isUserTeam,
  onRepoChange,
}) {
  const {
    inputProps,
    onSelect,
    repositories,
    repositoriesData,
  } = useRepositorySearch({
    provider,
    selectedInstallationId,
    resetInterval,
    selectedRepoName: selectedRepo?.name,
    onSelectRepo: onRepoChange,
    isUserTeam,
    onUpdated: () => undefined,
  })

  return (
    <FormFieldBlock
      id="repoSelection"
      label={label}
      error={repositoriesData.error ? `Failed to load repositories` : null}
      required
    >
      {controlProps => (
        <Combobox onSelect={onSelect}>
          <ComboboxInput
            autoComplete="off"
            id="repoSelection"
            disabled={selectedInstallationId === ``}
            data-cy="repo-list-search"
            placeholder={interpolateMessage(text.searchPlaceholder, {
              provider: sourceControlProviderLabels[provider],
            })}
            selectedOptionLabel={selectedRepo?.name}
            showToggleButton
            {...controlProps}
            {...inputProps}
          />
          <ComboboxPopover
            portal
            css={theme => ({ paddingBottom: theme.space[9] })}
          >
            <Button
              onClick={() => repositoriesData.refetch()}
              size="M"
              variant="GHOST"
              loading={repositoriesData.isRefetching}
              rightIcon={<MdRefresh />}
              css={refetchButtonCss}
            >
              Refresh the list
            </Button>

            {repositoriesData.isRefetching || repositoriesData.loading ? (
              <Loading
                delay={1000}
                message="loading your repositories..."
                bufferSize="padded"
              />
            ) : repositories.length > 0 ? (
              <React.Fragment>
                <ComboboxList
                  data-cy="repo-list"
                  aria-label="Select a repository"
                >
                  {repositories.map(repo => {
                    return (
                      <ComboboxOption
                        key={repo.id}
                        value={repo.name}
                        selected={selectedRepo?.id === repo.id}
                        highlightMatches={true}
                        data-cy={`repo-list-item-` + repo.name}
                      />
                    )
                  })}
                </ComboboxList>
              </React.Fragment>
            ) : (
              <ReposZeroState>No repos found</ReposZeroState>
            )}
          </ComboboxPopover>
        </Combobox>
      )}
    </FormFieldBlock>
  )
}

export function SiteNameField({
  value,
  onFocus,
  onChange,
  validationError,
  serverError,
  error,
  label,
  layout,
}) {
  const finalError = !value
    ? `Enter a site name`
    : error || serverError || validationError

  return (
    <InputFieldBlock
      id="site-name"
      label={label}
      hint={!finalError && "A unique site name is required"}
      error={finalError}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      layout={layout}
    />
  )
}
