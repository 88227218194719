import * as React from "react"
import { MdAdd, MdRemove } from "react-icons/md"
import { useCmsIntegration } from "@modules/cms/shared/hooks/useCmsIntegration"
import { Button, ThemeCss } from "gatsby-interface"
import Loading from "@modules/ui/components/Loading"
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@reach/disclosure"
import { CmsManualIntegrationRow } from "@modules/cms/integration/components/CmsManualIntegrationRow"
import { ManualWebhookIntegrations } from "@modules/cms/integration/components/ManualWebhookIntegrations"
import { CmsIntegrationRow } from "@modules/cms/integration/components/CmsIntegrationRow"
import { CmsIntegrationsComing } from "@modules/cms/integration/components/CmsIntegrationsComing"
import { useCmsIntegrationControls } from "@modules/cms/integration/hooks/useCmsIntegrationControls"
import { useIntegrationSuggestions } from "@modules/cms/integration/hooks/useIntegrationSuggestions"
import { SettingsBlock, SettingsBlockContent } from "@modules/site/settings"
import { importSite as importSiteText } from "@modules/locales/default.json"
import { CmsProvider } from "@modules/graphql/types"

const suggestionsBlockCss: ThemeCss = _theme => ({
  minWidth: 0,
})

export type IntegrationSuggestionsProps = {
  siteId: string
  nameWithOwner: string
  cmsIntegrations: CmsProvider[]
}

export function IntegrationSuggestions({
  siteId,
  nameWithOwner,
  cmsIntegrations,
}: IntegrationSuggestionsProps) {
  const { cmsIntegrations: manualCmsIntegrations } = useCmsIntegration()
  const orgSiteName = nameWithOwner
  const {
    setCmsToEdit,
    setCmsToRemove,
    controlModals,
  } = useCmsIntegrationControls(siteId, orgSiteName)
  const [data, { loading: suggestionsLoading }] = useIntegrationSuggestions(
    siteId,
    cmsIntegrations
  )

  if (suggestionsLoading) {
    return (
      <Loading
        delay={1000}
        message={importSiteText.messages.loadingIntegrations}
      />
    )
  }

  if (!cmsIntegrations) {
    return null
  }

  const suggestedIntegrations = data.suggestedIntegrations || []
  const otherIntegrations = data.otherIntegrations || []

  const hasSuggestedIntegrations = suggestedIntegrations.length > 0

  const renderIntegrationRow = (cmsIntegration: CmsProvider) => {
    return (
      <CmsIntegrationRow
        key={cmsIntegration.name}
        cms={cmsIntegration}
        editable={true}
        onEdit={() => setCmsToEdit(cmsIntegration)}
        onDelete={() => setCmsToRemove(cmsIntegration)}
      />
    )
  }

  const secondaryIntegrationsList = (
    <SettingsBlock
      title={
        hasSuggestedIntegrations
          ? importSiteText.headers.cmsOtherIntegrations
          : importSiteText.headers.cmsIntegrations
      }
      docUrl={hasSuggestedIntegrations ? null : "/docs/integrations"}
      id={hasSuggestedIntegrations ? `other-integrations` : `all-integrations`}
      data-cy={
        hasSuggestedIntegrations ? `other-integrations` : `all-integrations`
      }
      css={suggestionsBlockCss}
    >
      <SettingsBlockContent>
        {otherIntegrations.map(renderIntegrationRow)}
        {(manualCmsIntegrations || []).map((cms: CmsProvider) => (
          <CmsManualIntegrationRow key={cms.name} {...cms} />
        ))}
        <CmsIntegrationsComing />
        <ManualWebhookIntegrations siteId={siteId} />
      </SettingsBlockContent>
    </SettingsBlock>
  )

  const hasConnectedOtherIntegrations = otherIntegrations.some(
    ({ connected }) => connected
  )

  return (
    <div css={theme => ({ display: `grid`, gridGap: theme.space[7] })}>
      {hasSuggestedIntegrations && (
        <SettingsBlock
          title={importSiteText.headers.cmsSuggestedIntegrations}
          docUrl="/docs/integrations"
          id="suggested-integrations"
          css={suggestionsBlockCss}
        >
          <SettingsBlockContent>
            {suggestedIntegrations.map(renderIntegrationRow)}
          </SettingsBlockContent>
        </SettingsBlock>
      )}
      {hasSuggestedIntegrations ? (
        <RemainingIntegrationsDisclosure
          defaultIsOpen={hasConnectedOtherIntegrations}
        >
          {secondaryIntegrationsList}
        </RemainingIntegrationsDisclosure>
      ) : (
        secondaryIntegrationsList
      )}
      {controlModals}
    </div>
  )
}

type RemainingIntegrationsDisclosureProps = {
  children: React.ReactNode
  defaultIsOpen?: boolean
}

function RemainingIntegrationsDisclosure({
  children,
  defaultIsOpen = false,
}: RemainingIntegrationsDisclosureProps) {
  const [isOpen, setOpen] = React.useState<boolean>(defaultIsOpen)

  return (
    <div css={{ minWidth: 0 }}>
      <Disclosure open={isOpen} onChange={() => setOpen(!isOpen)}>
        <DisclosureButton
          as={Button}
          variant="SECONDARY"
          rightIcon={isOpen ? <MdRemove /> : <MdAdd />}
          css={theme => ({ marginBottom: theme.space[3] })}
        >
          {importSiteText.actions.seeMoreCmsIntegrations}
        </DisclosureButton>
        <DisclosurePanel>{children}</DisclosurePanel>
      </Disclosure>
    </div>
  )
}
