import React from "react"
import LoadingFromExternalWindow from "@modules/ui/components/LoadingFromExternalWindow"

function InstallationWindowLoader({ onPopInstallationWindow, message }) {
  return (
    <LoadingFromExternalWindow
      delay={1000}
      message={message}
      bufferSize="padded"
      onClick={onPopInstallationWindow}
    />
  )
}

export default InstallationWindowLoader
