import { useQuery } from "react-apollo"
import { GET_DEPENDENCIES } from "../queries"

export function useSiteDependencies(siteId) {
  const { loading, data, error } = useQuery(GET_DEPENDENCIES, {
    variables: { siteId },
  })

  const dependenciesForSite = data?.gatsbyDependenciesForSite || []

  return [dependenciesForSite, { loading, error }]
}
