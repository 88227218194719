import * as React from "react"
import { navigate } from "gatsby"
import { useCmsIntegration } from "@modules/cms/shared/hooks/useCmsIntegration"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"
import { Heading, Text, ThemeCss } from "gatsby-interface"
import { importSite as importSiteText } from "@modules/locales/default.json"
import { Footer } from "@modules/site/create/shared/components/Footer"
import { useTracker } from "@modules/analytics"
import Loading from "@modules/ui/components/Loading"
import { IntegrationSuggestions } from "./IntegrationSuggestions"
import { getPathToImportSite } from "@modules/site/create/shared/utils"
import { CmsProvider } from "@modules/graphql/types"
import { useRepositoryFromSiteQuery } from "@modules/site/create/Import/queries.generated"
import { FormattedMessage } from "@modules/locales"
import { ErrorAlert } from "@modules/ui/components/ErrorAlert"
import { HelpLink } from "@modules/ui/components/HelpLink"

const emphasizedCss: ThemeCss = theme => ({
  color: theme.colors.lilac,
})

const introWrapperCss: ThemeCss = theme => ({
  margin: `${theme.space[7]} 0`,
})

const introParagraphCss: ThemeCss = theme => ({
  margin: `${theme.space[4]} 0`,
})

export type CmsIntegrationsStepProps = {
  organizationId: string
  siteId: string
}

export function CmsIntegrationsStep({
  organizationId,
  siteId,
}: CmsIntegrationsStepProps) {
  const { data } = useRepositoryFromSiteQuery({
    variables: {
      siteId,
    },
  })
  const { cmsIntegrations, loading, error } = useCmsIntegration(siteId)
  const { trackButtonClicked } = useTracker()

  const nameWithOwner = data?.repositoryFromSite?.nameWithOwner

  if (loading) {
    return (
      <Loading
        delay={1000}
        message={importSiteText.messages.loadingIntegrations}
      />
    )
  }

  if (error) {
    return (
      <NarrowSingleColumn>
        <ErrorAlert>{error.message}</ErrorAlert>
      </NarrowSingleColumn>
    )
  }

  return (
    <React.Fragment>
      <NarrowSingleColumn>
        {nameWithOwner && (
          <React.Fragment>
            <Heading
              css={{ textAlign: `center` }}
              data-cy="add-integration-title"
            >
              <FormattedMessage<"siteName", "strong">
                message={importSiteText.headers.cmsIntegrationsStep}
                values={{
                  siteName: nameWithOwner,
                }}
                tags={{
                  strong: function(content) {
                    return <strong css={emphasizedCss}>{content}</strong>
                  },
                }}
              />
            </Heading>
            <div css={introWrapperCss}>
              <Text css={introParagraphCss}>
                {importSiteText.messages.cmsIntegrationsIntro}
              </Text>
              <Text css={introParagraphCss}>
                <FormattedMessage<never, "link">
                  message={importSiteText.messages.cmsIntegraionsDocsInfo}
                  tags={{
                    link: function(content) {
                      return (
                        <HelpLink href="https://support.gatsbyjs.com/hc/en-us/sections/360011112314-Connecting-to-a-Content-Management-System">
                          {content}
                        </HelpLink>
                      )
                    },
                  }}
                />
              </Text>
            </div>
            <IntegrationSuggestions
              siteId={siteId}
              nameWithOwner={nameWithOwner}
              cmsIntegrations={cmsIntegrations}
            />
          </React.Fragment>
        )}
      </NarrowSingleColumn>
      <Footer
        cancel={() =>
          navigate(`${getPathToImportSite(organizationId)}/repository`)
        }
        submitForm={() => {
          if (!hasConnectedCmsIntegration(cmsIntegrations)) {
            trackButtonClicked(`Skip this step`, {
              siteId: siteId,
              uiSource: `New Site Wizard: integrations`,
            })
          }
          return navigate(
            `${getPathToImportSite(organizationId)}/${siteId}/setup`
          )
        }}
        buttonText={
          hasConnectedCmsIntegration(cmsIntegrations)
            ? importSiteText.actions.cmsIntegrationsNext
            : importSiteText.actions.cmsIntegrationsSkip
        }
      />
    </React.Fragment>
  )
}

function hasConnectedCmsIntegration(cmsIntegrations: CmsProvider[]) {
  return cmsIntegrations.find(cmsIntegration => cmsIntegration.connected)
}
