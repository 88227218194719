// THIS IS A GENERATED FILE
import * as Types from "../../graphql/types"

import {
  BillingFieldsFragment,
  CreditCardFieldsFragment,
  BillingPlanFieldsFragment,
  PermissionsForOrganizationFragment,
} from "../fragments.generated"
import gql from "graphql-tag"
import {
  BillingFieldsFragmentDoc,
  CreditCardFieldsFragmentDoc,
  BillingPlanFieldsFragmentDoc,
  PermissionsForOrganizationFragmentDoc,
} from "../fragments.generated"
import { useApolloFragment } from "apollo-fragment-react"

export type OrganizationSettingsFieldsFragment = {
  __typename?: "Organization"
} & Pick<Types.Organization, "id" | "name" | "status"> & {
    billing?: Types.Maybe<
      { __typename?: "BillingInformation" } & {
        creditCard?: Types.Maybe<
          { __typename?: "CreditCard" } & CreditCardFieldsFragment
        >
        plan?: Types.Maybe<
          { __typename?: "SubscriptionPlan" } & Pick<
            Types.SubscriptionPlan,
            "name"
          >
        >
      } & BillingFieldsFragment
    >
    permissions?: Types.Maybe<
      { __typename?: "PermissionsDigest" } & PermissionsForOrganizationFragment
    >
  }

export type OrganizationNameFieldsFragment = {
  __typename?: "Organization"
} & Pick<Types.Organization, "name">

export type OrganizationStatusFieldsFragment = {
  __typename?: "Organization"
} & Pick<Types.Organization, "status">

export const OrganizationSettingsFieldsFragmentDoc = gql`
  fragment organizationSettingsFields on Organization {
    id
    name
    status
    billing {
      ...billingFields
      creditCard {
        ...creditCardFields
      }
      plan {
        name
      }
    }
    permissions {
      ...permissionsForOrganization
    }
  }
  ${BillingFieldsFragmentDoc}
  ${CreditCardFieldsFragmentDoc}
  ${PermissionsForOrganizationFragmentDoc}
`
export const OrganizationNameFieldsFragmentDoc = gql`
  fragment organizationNameFields on Organization {
    name
  }
`
export const OrganizationStatusFieldsFragmentDoc = gql`
  fragment organizationStatusFields on Organization {
    status
  }
`

/**
 * __useOrganizationSettingsFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useOrganizationSettingsFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useOrganizationSettingsFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useOrganizationSettingsFieldsFragment('fragment-id');
 */
export function useOrganizationSettingsFieldsFragment(id: string) {
  return useApolloFragment<OrganizationSettingsFieldsFragment>(
    OrganizationSettingsFieldsFragmentDoc,
    id
  )
}
export type OrganizationSettingsFieldsFragmentHookResult = ReturnType<
  typeof useOrganizationSettingsFieldsFragment
>

/**
 * __useOrganizationNameFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useOrganizationNameFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useOrganizationNameFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useOrganizationNameFieldsFragment('fragment-id');
 */
export function useOrganizationNameFieldsFragment(id: string) {
  return useApolloFragment<OrganizationNameFieldsFragment>(
    OrganizationNameFieldsFragmentDoc,
    id
  )
}
export type OrganizationNameFieldsFragmentHookResult = ReturnType<
  typeof useOrganizationNameFieldsFragment
>

/**
 * __useOrganizationStatusFieldsFragment__
 * To read a fragment data from Apollo Cache, call `useOrganizationStatusFieldsFragment` and pass it the ID of the cached object.
 * When your component renders, `useOrganizationStatusFieldsFragment` returns an object from Apollo Client cache that contains data property
 * you can use to render your UI.
 *
 * @param id a string representing the ID of the cached object that will be passed into the useApolloFragment
 *
 * @example
 * const { data } = useOrganizationStatusFieldsFragment('fragment-id');
 */
export function useOrganizationStatusFieldsFragment(id: string) {
  return useApolloFragment<OrganizationStatusFieldsFragment>(
    OrganizationStatusFieldsFragmentDoc,
    id
  )
}
export type OrganizationStatusFieldsFragmentHookResult = ReturnType<
  typeof useOrganizationStatusFieldsFragment
>
