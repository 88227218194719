import * as React from "react"
import { SourceControlProvider, Repository } from "@modules/graphql/types"
import { ApolloStatus } from "@modules/enums/constants"
import { useRepositoriesForInstallation } from "./useRepositoriesForInstallation"

export function useRepositorySearch({
  provider,
  selectedInstallationId,
  onUpdated,
  onSelectRepo,
  selectedRepoName,
  isUserTeam,
}: {
  provider: SourceControlProvider
  selectedInstallationId: string
  onUpdated: () => void
  selectedRepoName: string
  onSelectRepo: (repo: Pick<Repository, "id" | "name"> | null) => void
  isUserTeam: boolean
}) {
  const [searchTerm, setSearchTerm] = React.useState<string>(selectedRepoName)

  React.useEffect(() => {
    setSearchTerm(selectedRepoName || "")
  }, [selectedRepoName])

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const searchText = e.target.value

    if (!searchText) {
      onSelectRepo(null)
    }
    setSearchTerm(searchText)
  }

  const [
    repositoriesForInstallation,
    { loading, error, networkStatus, refetch },
  ] = useRepositoriesForInstallation(
    {
      installationId: selectedInstallationId,
      provider,
      isUserTeam,
    },
    onUpdated
  )

  const initialLoading = loading && networkStatus !== ApolloStatus.Refetching
  const isRefetching = loading && networkStatus === ApolloStatus.Refetching

  const filteredRepos = repositoriesForInstallation.filter(repo => {
    if (searchTerm) {
      return repo.name?.toLowerCase().includes(searchTerm.toLowerCase())
    }

    return true
  })

  return {
    inputProps: {
      value: searchTerm,
      onChange: onInputChange,
    },
    onSelect: (repoName: string) => {
      const selectedRepo = repositoriesForInstallation.find(
        repo => repo.name === repoName
      )
      onSelectRepo(selectedRepo || null)
    },
    repositories: filteredRepos,
    repositoriesData: {
      refetch,
      loading: initialLoading,
      isRefetching,
      error,
    },
  }
}
