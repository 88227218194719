import * as React from "react"
import { Repository, SourceProviderTeam } from "@modules/graphql/types"

const repoFormInitialState: RepoFormState = {
  installation: "",
  repo: null,
  branch: "",
  isBranchInitialized: false,
  directoryPath: ``,
  siteName: ``,
  touchedSiteName: false,
  isValidSiteName: false,
}

export type RepoFormState = {
  installation: string
  repo: Repository | null
  branch: string
  isBranchInitialized: boolean
  directoryPath: string
  siteName: string
  touchedSiteName: boolean
  isValidSiteName: boolean
}

export enum RepoFormActionType {
  SelectInstallation = `SELECT_INSTALLATION`,
  SelectRepo = `SELECT_REPO`,
  InitializeBranch = `INITIALIZE_BRANCH`,
  SelectBranch = `SELECT_BRANCH`,
  ChangeDirectoryPath = `CHANGE_DIRECTORY_PATH`,
  TouchSiteName = `TOUCH_SITE_NAME`,
  ChangeSiteName = `CHANGE_SITE_NAME`,
  ValidateSiteName = "VALIDATE_SITE_NAME",
}

export type RepoFormAction =
  | {
      type: RepoFormActionType.SelectInstallation
      payload: string
    }
  | {
      type: RepoFormActionType.SelectRepo
      payload: Repository | null
    }
  | {
      type: RepoFormActionType.InitializeBranch
      payload: string
    }
  | {
      type: RepoFormActionType.SelectBranch
      payload: string
    }
  | {
      type: RepoFormActionType.ChangeDirectoryPath
      payload: string
    }
  | {
      type: RepoFormActionType.ChangeSiteName
      payload: string
    }
  | {
      type: RepoFormActionType.ValidateSiteName
      payload: boolean
    }
  | {
      type: RepoFormActionType.TouchSiteName
    }

function repoFormReducer(
  state: RepoFormState,
  action: RepoFormAction
): RepoFormState {
  switch (action.type) {
    case RepoFormActionType.SelectInstallation: {
      return {
        ...repoFormInitialState,
        installation: action.payload,
      }
    }
    case RepoFormActionType.SelectRepo: {
      const { touchedSiteName, siteName } = state
      const repo = action.payload || null

      return {
        ...state,
        repo,
        branch: ``,
        isBranchInitialized: false,
        siteName: touchedSiteName ? siteName : repo?.name || "",
      }
    }
    case RepoFormActionType.InitializeBranch: {
      const { touchedSiteName, siteName, repo } = state
      const branch = action.payload || ""

      return {
        ...state,
        branch,
        isBranchInitialized: true,
        siteName: touchedSiteName ? siteName : `${repo?.name || ""}-${branch}`,
      }
    }
    case RepoFormActionType.SelectBranch: {
      const { touchedSiteName, siteName, repo } = state
      const branch = action.payload || ""

      return {
        ...state,
        branch,
        siteName: touchedSiteName ? siteName : `${repo?.name || ""}-${branch}`,
      }
    }
    case RepoFormActionType.ChangeDirectoryPath: {
      return {
        ...state,
        directoryPath: action.payload,
      }
    }
    case RepoFormActionType.TouchSiteName: {
      return {
        ...state,
        touchedSiteName: true,
      }
    }
    case RepoFormActionType.ChangeSiteName: {
      return {
        ...state,
        siteName: action.payload,
      }
    }

    case RepoFormActionType.ValidateSiteName: {
      return {
        ...state,
        isValidSiteName: action.payload,
      }
    }
    default:
      return state
  }
}

export function useRepoForm() {
  return React.useReducer(repoFormReducer, repoFormInitialState)
}

export function getCurrentSourceProviderTeam(
  selectedSourceProviderTeamId?: string,
  currentOrgName?: string,
  sourceProviderTeams: Pick<SourceProviderTeam, "id" | "name">[] = []
) {
  if (sourceProviderTeams.length === 0) {
    return undefined
  }

  if (selectedSourceProviderTeamId) {
    return sourceProviderTeams.find(
      team => team.id === selectedSourceProviderTeamId
    )
  }

  if (currentOrgName) {
    const activeTeam = sourceProviderTeams.find(
      team => team.name.toLowerCase() === currentOrgName.toLowerCase()
    )

    return activeTeam || sourceProviderTeams[0]
  }

  return sourceProviderTeams[0]
}
