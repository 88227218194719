import * as React from "react"
import { ThemeCss, Button, Spacer } from "gatsby-interface"
import Loading from "@modules/ui/components/Loading"
import {
  importSite as importSiteText,
  ui as uiText,
} from "@modules/locales/default.json"
import { useSiteRepositoryFieldsFragment } from "@modules/site/shared/fragments.generated"
import { useCmsIntegrationsForSiteQuery } from "@modules/cms/shared/queries.generated"
import {
  useImportedSiteDetailsQuery,
  usePublishSiteMutation,
} from "@modules/site/create/Import/queries.generated"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import { FormattedMessage } from "@modules/locales"
import { useTracker, SegmentEventType } from "@modules/analytics"
import { WizardFooter } from "@modules/ui/components/WizardFooter"
import { ImportedSiteInformation } from "./ImportedSiteInformation"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"
import { MdArrowForward } from "react-icons/md"
import { sourceControlProviderLabels } from "@modules/sourceControlProvider"
import {
  ErrorAlert,
  useTriggerErrorAlert,
} from "@modules/ui/components/ErrorAlert"

const repoNameCss: ThemeCss = theme => ({
  color: theme.colors.gatsby,
})

export type SetupImportedSiteStepProps = {
  organizationId: string
  siteId: string
  onPublished: () => void
  onGoBack: () => void
}

export function SetupImportedSiteStep({
  organizationId,
  siteId,
  onPublished,
  onGoBack,
}: SetupImportedSiteStepProps) {
  const {
    data: siteDetailsData,
    loading: siteDetailsLoading,
    error: siteDetailsError,
  } = useImportedSiteDetailsQuery({
    variables: { id: siteId },
    fetchPolicy: `cache-and-network`,
  })
  const {
    data: siteIntegrationsData,
    loading: siteIntegrationsLoading,
    error: siteIntegrationsError,
  } = useCmsIntegrationsForSiteQuery({
    variables: {
      siteId,
      connected: true,
    },
  })
  const [
    publishSite,
    { loading: isPubishing, errorAlert: publishError },
  ] = usePublishSite(siteId)

  if (siteDetailsError || siteIntegrationsError) {
    return (
      <NarrowSingleColumn>
        {siteDetailsError && (
          <ErrorAlert>{siteDetailsError.message}</ErrorAlert>
        )}
        {siteIntegrationsError && (
          <ErrorAlert>{siteIntegrationsError.message}</ErrorAlert>
        )}
      </NarrowSingleColumn>
    )
  }

  if (siteDetailsLoading || siteIntegrationsLoading) {
    return (
      <Loading
        delay={1000}
        message={importSiteText.messages.loadingDataForSetup}
        bufferSize="padded"
      />
    )
  }

  const repoName = siteDetailsData?.siteDetails?.repository?.nameWithOwner || ""

  return (
    <React.Fragment>
      <NarrowSingleColumn>
        <WizardStepHeader
          title={
            <FormattedMessage<"repoName", "strong">
              message={importSiteText.headers.setupSiteStep}
              values={{
                repoName,
              }}
              tags={{
                strong: function(content) {
                  return <span css={repoNameCss}>{content}</span>
                },
              }}
            />
          }
        />

        <ImportedSiteInformation
          siteId={siteId}
          organizationId={organizationId}
          siteRepositoryNameWithOwner={repoName}
          branch={siteDetailsData?.siteDetails?.branch || ""}
          cmsIntegrationsForSite={
            siteIntegrationsData?.cmsIntegrationsForSite || []
          }
        />

        {publishError && (
          <React.Fragment>
            <Spacer size={5} />
            {publishError}
          </React.Fragment>
        )}
      </NarrowSingleColumn>

      <WizardFooter
        goBackButton={
          <Button variant="SECONDARY" onClick={() => onGoBack()}>
            {uiText.actions.back}
          </Button>
        }
        goNextButton={
          <Button
            onClick={() => publishSite(onPublished)}
            loading={isPubishing}
            rightIcon={<MdArrowForward />}
          >
            {importSiteText.actions.publishSite}
          </Button>
        }
      />
    </React.Fragment>
  )
}

function usePublishSite(siteId: string) {
  const { data: repositoryData } = useSiteRepositoryFieldsFragment(siteId)
  const { trackButtonClicked, trackSegment } = useTracker()
  const [setError, errorAlert] = useTriggerErrorAlert()

  const [publishSiteMutation, mutationResult] = usePublishSiteMutation()

  const publish = (onSuccess: () => void) => {
    trackButtonClicked("Preview Site", {
      siteId,
      uiSource: `Create and setup site`,
    })

    const provider = repositoryData?.repository?.provider

    trackSegment({
      type: SegmentEventType.Track,
      event: "Created a Site",
      properties: {
        vcsType: provider ? sourceControlProviderLabels[provider] : "",
      },
    })

    publishSiteMutation({
      variables: {
        id: siteId,
        environmentVariables: [],
      },
    })
      .then(result => {
        if (result?.data?.publishSite) {
          onSuccess()
        }
      })
      .catch(err => setError(err))
  }

  return [publish, { ...mutationResult, errorAlert }] as const
}
