import { useState } from "react"
import { useTracker } from "@modules/analytics"
import { makeClosableWindow } from "./window"

const INTERVAL_AFTER_POPPING = 8000

const GITHUB_APP_URL = `https://github.com/apps/${process.env.GATSBY_GITHUB_APP_NAME}/installations/new`

export default function useInstallationWindow(
  onCloseWindow,
  defaultInterval = 60000
) {
  const { trackButtonClicked } = useTracker()
  const [pollInterval, setPollInterval] = useState(defaultInterval)

  const [openGithubInstallWindow] = makeClosableWindow(
    GITHUB_APP_URL,
    onCloseWindow
  )

  const popInstallationWindow = (trackName, trackOpts) => {
    trackButtonClicked(trackName, trackOpts)

    setPollInterval(INTERVAL_AFTER_POPPING)

    openGithubInstallWindow()
  }

  const resetInterval = () => {
    setPollInterval(defaultInterval)
  }

  return {
    pollInterval,
    resetInterval,
    popInstallationWindow,
  }
}
