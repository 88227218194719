import { useGenericError } from "@modules/modal"
import {
  useCreateSiteMutation,
  CreateSiteMutationVariables,
} from "@modules/onboarding/gaFlow/queries.generated"

export const useCreateSite = () => {
  const [createSiteMutation, { loading }] = useCreateSiteMutation()

  const throwError = useGenericError()

  const createSite = ({
    organizationId,
    provider,
    nameWithOwner,
    directoryPath,
    branch,
    selectedInstallationId,
    successAction,
    publicName,
  }: CreateSiteMutationVariables & {
    successAction: (siteId: string) => void
  }) =>
    createSiteMutation({
      variables: {
        organizationId,
        provider,
        nameWithOwner,
        directoryPath,
        branch,
        selectedInstallationId,
        publicName,
      },
    })
      .then(({ data }) => {
        const createSite = data && data.createSite
        const validation = createSite && createSite.validation
        const site = createSite && createSite.site
        const siteId = site && site.id

        const success = validation && validation.success
        const errorType = validation && validation.errorType

        if (success) {
          return successAction(siteId)
        }

        return throwError({ type: errorType })
      })
      .catch(err => throwError({ message: err.message }))

  return [createSite, loading] as const
}
