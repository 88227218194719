import { useSiteDependencies } from "@modules/site/details/hooks/useSiteDependencies"
import { CmsVendor } from "@modules/graphql/types"

const cmsDependencies = {
  [CmsVendor.Contentful]: `gatsby-source-contentful`,
  [CmsVendor.Cosmicjs]: `gatsby-source-cosmicjs`,
  [CmsVendor.Datocms]: `gatsby-source-datocms`,
  [CmsVendor.Sanity]: `@sanity/cli`,
}

export function useIntegrationSuggestions(siteId, cmsIntegrations = []) {
  const [siteDependencies, { loading }] = useSiteDependencies(siteId)

  const installedDependenciesNames = siteDependencies.map(({ name }) => name)
  const availableItegrations = cmsIntegrations || []
  const suggestedIntegrations = []
  const otherIntegrations = []

  availableItegrations.forEach(cmsIntegration => {
    const cmsDependency = cmsDependencies[cmsIntegration.name]
    const shouldSuggest =
      cmsDependency && installedDependenciesNames.includes(cmsDependency)

    if (shouldSuggest) {
      suggestedIntegrations.push(cmsIntegration)
    } else {
      otherIntegrations.push(cmsIntegration)
    }
  })

  return [
    {
      suggestedIntegrations,
      otherIntegrations,
    },
    { loading },
  ]
}
