import * as React from "react"
import { MdInfo } from "react-icons/md"
import { createSite as text } from "@modules/locales/default.json"
import {
  Notification,
  CardSection,
  ThemeCss,
  Card,
  Heading,
} from "gatsby-interface"
import { CmsProvider } from "@modules/graphql/types"
import { EnvironmentVariablesCard } from "@modules/site/envVars/components/EnvironmentVariablesCard"
import { useEnvironmentVariablesForSiteQuery } from "@modules/site/envVars/queries.generated"
import { FormattedMessage, interpolateMessage } from "@modules/locales"
import { importSite as importSiteText } from "@modules/locales/default.json"
import { HelpLink } from "@modules/ui/components/HelpLink"
import SiteBranch from "@modules/site/shared/components/SiteBranch"
import { useCmsIntegrationControls } from "@modules/cms/integration/hooks/useCmsIntegrationControls"
import { SettingsBlock, SettingsBlockContent } from "@modules/site/settings"
import { CmsIntegrationRow } from "@modules/cms/integration/components/CmsIntegrationRow"

const cardsContainerCss: ThemeCss = theme => ({
  alignItems: "start",
  display: "grid",
  gridGap: theme.space[7],
  width: "100%",
  gridTemplateColumns: "100%",
})

export type ImportedSiteInformationProps = {
  siteId: string
  organizationId: string
  siteRepositoryNameWithOwner: string
  branch: string
  cmsIntegrationsForSite: Pick<
    CmsProvider,
    "name" | "formattedName" | "logoUrl" | "features" | "connected"
  >[]
}

export function ImportedSiteInformation({
  siteId,
  organizationId,
  siteRepositoryNameWithOwner,
  branch,
  cmsIntegrationsForSite,
}: ImportedSiteInformationProps) {
  const {
    setCmsToEdit,
    setCmsToRemove,
    controlModals,
  } = useCmsIntegrationControls(siteId, siteRepositoryNameWithOwner)
  const { data } = useEnvironmentVariablesForSiteQuery({
    variables: {
      id: siteId,
    },
  })
  const environmentVariables = data?.environmentVariablesForSite || []

  const filteredCmsIntegrationsForSite = cmsIntegrationsForSite.filter(
    integration => integration.connected
  )

  return (
    <React.Fragment>
      {cmsIntegrationsForSite.length === 0 && (
        <Notification
          tone="WARNING"
          variant="SECONDARY"
          Icon={MdInfo}
          content={interpolateMessage<"integrationsStep">(
            importSiteText.messages.setupLater,
            { integrationsStep: importSiteText.labels.integrationsStep }
          )}
          css={theme => ({
            marginBottom: theme.space[9],
          })}
        />
      )}
      <div css={cardsContainerCss}>
        <Card as="div">
          <CardSection>
            <Heading as="h2" css={theme => ({ fontSize: theme.fontSizes[4] })}>
              {importSiteText.headers.selectedBranch}
            </Heading>
            <SiteBranch css={theme => ({ fontSize: theme.fontSizes[3] })}>
              {branch}
            </SiteBranch>
          </CardSection>
        </Card>
        {filteredCmsIntegrationsForSite.length > 0 && (
          <SettingsBlock
            title={importSiteText.headers.cmsIntegrations}
            docUrl="/docs/integrations"
            id="review-integrations"
          >
            <SettingsBlockContent>
              {filteredCmsIntegrationsForSite.map(cmsIntegration => {
                return (
                  <CmsIntegrationRow
                    key={cmsIntegration.name}
                    cms={cmsIntegration}
                    editable={true}
                    onEdit={() => setCmsToEdit(cmsIntegration)}
                    onDelete={() => setCmsToRemove(cmsIntegration)}
                  />
                )
              })}
            </SettingsBlockContent>
          </SettingsBlock>
        )}

        <EnvironmentVariablesCard
          siteId={siteId}
          organizationId={organizationId}
          siteBuildsEnabled={true}
          uiSource="New Site Wizard: setup"
          defaultEditMode={true}
        >
          {environmentVariables.length > 0 && (
            <CardSection applyPaddingBottom={false}>
              <FormattedMessage<never, "link">
                message={text.messages.autoAddedVariables}
                tags={{
                  link: function(content) {
                    return (
                      <HelpLink
                        href={`${window.location.origin}/docs/managing-environment-variables/`}
                      >
                        {content}
                      </HelpLink>
                    )
                  },
                }}
              />
            </CardSection>
          )}
        </EnvironmentVariablesCard>
      </div>
      {controlModals}
    </React.Fragment>
  )
}
