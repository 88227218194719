import {
  useRepositoriesForInstallationQuery,
  RepositoriesForInstallationQueryVariables,
  useSourceOrganizationRepositoryUpdatedSubscription,
} from "@modules/site/create/Import/queries.generated"
import { CompactArray } from "@modules/types/shared"

export function useRepositoriesForInstallation(
  {
    installationId,
    provider,
    isUserTeam,
  }: RepositoriesForInstallationQueryVariables,
  onUpdated: () => void
) {
  const { data, ...rest } = useRepositoriesForInstallationQuery({
    variables: {
      installationId,
      provider,
      isUserTeam,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: `cache-and-network`,
    skip: !installationId,
  })

  useSourceOrganizationRepositoryUpdatedSubscription({
    variables: {
      organizationInstallationId: installationId,
    },
    skip: !installationId,
    onSubscriptionData: () => {
      onUpdated()
      rest.refetch()
      return
    },
  })

  const repositoriesForInstallation = data?.repositoriesForInstallation || []

  return [
    repositoriesForInstallation as CompactArray<
      typeof repositoriesForInstallation
    >,
    rest,
  ] as const
}
