import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import * as qs from "query-string"
import {
  StepIndicator,
  StepIndicatorStep,
  ThemeCss,
  StepIndicatorStepProps,
} from "gatsby-interface"
import { importSite as importSiteText } from "@modules/locales/default.json"
import { getUserId } from "@modules/analytics/utils"
import { PickRepository } from "@modules/onboarding/createFromExisting/components/PickRepository"
import { CmsIntegrationsStep } from "@modules/site/create/Import/components/CmsIntegrationsStep"
import {
  getPathToImportSite,
  getPathToCreateSite,
} from "@modules/site/create/shared/utils"
import { SiteCreationWizardHeader } from "../../shared/components/SiteCreationWizardHeader"
import { SourceControlProvider } from "@modules/graphql/types"
import { SourceProviderSelector } from "./SourceProviderSelector"
import { SetupImportedSiteStep } from "./SetupImportedSiteStep"
import { LoginOption } from "@modules/userAccount/constants"
import { processSignupAttempt } from "@modules/auth/utils"
import { getPathToSiteDetails } from "@modules/site/details/utils"

const siteRootCss: ThemeCss = theme => ({
  paddingBottom: "3rem",

  [theme.mediaQueries.phablet]: {
    paddingBottom: "7.5rem",
  },
})

const mapProviderToLoginOption: Record<SourceControlProvider, LoginOption> = {
  [SourceControlProvider.Github]: LoginOption.Github,
  [SourceControlProvider.Gitlab]: LoginOption.Gitlab,
  [SourceControlProvider.Bitbucket]: LoginOption.Bitbucket,
}

enum ImportSiteWizardStep {
  SourceProvider = `sourceProvider`,
  Repository = `repository`,
  Integrations = `integrations`,
  Setup = `setup`,
}

const stepLabels: Record<ImportSiteWizardStep, string> = {
  [ImportSiteWizardStep.SourceProvider]:
    importSiteText.labels.sourceProviderStep,
  [ImportSiteWizardStep.Repository]: importSiteText.labels.repositoryStep,
  [ImportSiteWizardStep.Integrations]: importSiteText.labels.integrationsStep,
  [ImportSiteWizardStep.Setup]: importSiteText.labels.setupStep,
}

export type ImportSiteWizardProps = RouteComponentProps<{
  organizationId: string
  siteId: string
}>

type StepInfo = {
  step: ImportSiteWizardStep
  label: string
  to: string
  status: StepIndicatorStepProps["status"]
}

export function ImportSiteWizard({
  organizationId,
  siteId,
  location,
}: ImportSiteWizardProps) {
  const {
    activeStep,
    selectedSourceProvider,
    steps,
  } = useImportSiteWizardSteps({ organizationId, siteId, location })

  if (!organizationId) {
    return null
  }

  return (
    <main css={siteRootCss}>
      <SiteCreationWizardHeader />
      <StepIndicator>
        {steps.map(step => (
          <StepIndicatorStep key={step.step} to={step.to} status={step.status}>
            {step.label}
          </StepIndicatorStep>
        ))}
      </StepIndicator>
      {activeStep === ImportSiteWizardStep.SourceProvider && (
        <SourceProviderSelector
          onSelect={sourceProvider => {
            navigate(getPathToRepositoryStep(organizationId, sourceProvider))
          }}
          onGoBack={() =>
            organizationId && navigate(getPathToCreateSite(organizationId))
          }
          onAddProvider={sourceProvider => {
            processSignupAttempt(
              mapProviderToLoginOption[sourceProvider],
              {
                csrfToken: getUserId(),
                attachProvider: true,
                errorRedirectUrl: `${getPathToImportSite(
                  organizationId
                )}/repository`,
                loginRedirectUrl: getPathToRepositoryStep(
                  organizationId,
                  sourceProvider
                ),
              },
              {}
            )
          }}
        />
      )}
      {activeStep === ImportSiteWizardStep.Repository && (
        <PickRepository
          selectRepo={(siteId: string) =>
            navigate(
              `${getPathToImportSite(organizationId)}/${siteId}/integrations`
            )
          }
          organizationId={organizationId}
          provider={selectedSourceProvider || undefined}
        />
      )}
      {activeStep === ImportSiteWizardStep.Integrations && siteId && (
        <CmsIntegrationsStep organizationId={organizationId} siteId={siteId} />
      )}
      {activeStep === ImportSiteWizardStep.Setup && siteId && (
        <SetupImportedSiteStep
          organizationId={organizationId}
          siteId={siteId}
          onPublished={() => {
            navigate(
              `${getPathToSiteDetails(siteId, organizationId)}?newsite=true`
            )
          }}
          onGoBack={() =>
            navigate(
              `${getPathToImportSite(organizationId)}/${siteId}/integrations`
            )
          }
        />
      )}
    </main>
  )
}

function useImportSiteWizardSteps({
  siteId,
  location,
}: ImportSiteWizardProps): {
  activeStep: ImportSiteWizardStep
  selectedSourceProvider: SourceControlProvider | null
  steps: StepInfo[]
} {
  const searchParams = qs.parse(location?.search || ``)
  const selectedSourceProvider =
    (searchParams.sourceProvider as SourceControlProvider) || null
  const isOnIntegrationStep = location?.pathname?.includes(`integrations`)

  const steps: ImportSiteWizardStep[] = [
    ImportSiteWizardStep.SourceProvider,
    ImportSiteWizardStep.Repository,
    ImportSiteWizardStep.Integrations,
    ImportSiteWizardStep.Setup,
  ]

  let activeStep: ImportSiteWizardStep
  if (siteId) {
    activeStep = isOnIntegrationStep
      ? ImportSiteWizardStep.Integrations
      : ImportSiteWizardStep.Setup
  } else {
    activeStep = !selectedSourceProvider
      ? ImportSiteWizardStep.SourceProvider
      : ImportSiteWizardStep.Repository
  }
  const activeStepIndex = steps.indexOf(activeStep)

  return {
    activeStep,
    selectedSourceProvider,
    steps: steps.map((step, stepIndex) => {
      return {
        step,
        label: stepLabels[step],
        status:
          activeStep === step
            ? `ACTIVE`
            : stepIndex < activeStepIndex
            ? `DONE`
            : `DEFAULT`,
        to: ``,
      }
    }),
  }
}

function getPathToWizardStep(
  organizationId: string,
  step: ImportSiteWizardStep,
  siteId?: string
) {
  return `${getPathToImportSite(organizationId)}/${
    siteId ? `${siteId}/` : ``
  }${step}`
}

function getPathToRepositoryStep(
  organizationId: string,
  sourceProvider: SourceControlProvider
) {
  return `${getPathToWizardStep(
    organizationId,
    ImportSiteWizardStep.Repository
  )}?sourceProvider=${sourceProvider}`
}
