import * as React from "react"
import { SourceControlProvider } from "@modules/graphql/types"
import { useAvailableSourceProvidersQuery } from "@modules/site/create/Import/queries.generated"
import { ThemeCss, Button } from "gatsby-interface"
import { NarrowSingleColumn } from "@modules/ui/layouts/Containers"
import { WizardFooter } from "@modules/ui/components/WizardFooter"
import { WizardStepHeader } from "@modules/ui/components/WizardStepHeader"
import {
  importSite as importSiteText,
  ui as uiText,
} from "@modules/locales/default.json"
import { interpolateMessage } from "@modules/locales"
import {
  SourceControlProviderIcon,
  sourceControlProviderLabels,
  useSupportedSourceControlProviders,
} from "@modules/sourceControlProvider"

const providerCardCss: ThemeCss = theme => ({
  background: theme.colors.white,
  border: `none`,
  borderRadius: theme.radii[2],
  boxShadow: theme.shadows.raised,
  margin: theme.space[3],
  paddingTop: 0,
  paddingBottom: theme.space[7],
  paddingLeft: 0,
  paddingRight: 0,
  cursor: `pointer`,
  color: theme.colors.gatsby,
  fontFamily: theme.fonts.heading,
  fontSize: theme.fontSizes[3],
  lineHeight: theme.lineHeights.dense,
  fontWeight: theme.fontWeights.semiBold,
  minHeight: 140,
  minWidth: 144,
  display: `inline-flex`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `flex-end`,
  "&:hover, &:focus": {
    textDecoration: `underline`,
  },
})

const providerIconCss: ThemeCss = theme => ({
  marginBottom: theme.space[4],
  color: `initial`,
  fontSize: theme.fontSizes[10],
})

export type SourceProviderSelectorProps = {
  onSelect: (provider: SourceControlProvider) => void
  onGoBack: () => void
  onAddProvider: (provider: SourceControlProvider) => void
}

export function SourceProviderSelector({
  onSelect,
  onGoBack,
  onAddProvider,
}: SourceProviderSelectorProps) {
  const { data } = useAvailableSourceProvidersQuery()
  const availableProviders = useSupportedSourceControlProviders()

  return (
    <React.Fragment>
      <NarrowSingleColumn>
        <WizardStepHeader title={importSiteText.headers.sourceProviderStep}>
          {importSiteText.messages.sourceProviderIntro}
        </WizardStepHeader>
        <div css={{ textAlign: `center` }}>
          {availableProviders.map(sourceProvider => {
            return (
              <button
                key={sourceProvider}
                css={providerCardCss}
                onClick={() => {
                  const isConnected = data?.availableSourceProviders?.some(
                    ({ source, connected }) => {
                      return source === sourceProvider && connected
                    }
                  )

                  if (isConnected) {
                    onSelect(sourceProvider)
                  } else {
                    onAddProvider(sourceProvider)
                  }
                }}
                aria-label={interpolateMessage<"sourceProvider">(
                  importSiteText.actions.chooseSourceProvider,
                  {
                    sourceProvider: sourceControlProviderLabels[sourceProvider],
                  }
                )}
              >
                <SourceControlProviderIcon
                  sourceControlProvider={sourceProvider}
                  size="inherit"
                  css={providerIconCss}
                  aria-hidden
                />
                {sourceControlProviderLabels[sourceProvider]}
              </button>
            )
          })}
        </div>
      </NarrowSingleColumn>
      <WizardFooter
        goBackButton={
          <Button variant="SECONDARY" onClick={() => onGoBack()}>
            {uiText.actions.back}
          </Button>
        }
        goNextButton={<div />}
      />
    </React.Fragment>
  )
}
