import gql from "graphql-tag"

export const GET_DEPENDENCIES = gql`
  query gatsbyDependenciesForSite($siteId: UUID!) {
    gatsbyDependenciesForSite(siteId: $siteId) {
      name
      installedVersion
    }
  }
`
