import React from "react"
import { Announcement } from "gatsby-interface"

export function CmsIntegrationsComing() {
  return (
    <Announcement>
      We are working on adding more integrations all the time &mdash; watch your
      inbox!
    </Announcement>
  )
}
